<template>
  <div>
    <!-- {{ hasOffer }} -->
    <!-- {{ shopUploadEntries }} -->
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <b-table
            ref="refListTable"
            striped
            hover
            responsive
            :items="shopUploadEntries"
            :fields="fields"
            :filter="filter"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
          >
            <template #cell(shopUploadEntries)="data">
              <span v-if="data.item.offer"
                ><b-link
                  :to="{
                    name: 'offers-view',
                    params: { id: data.item.offer.id },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  Angebot
                </b-link>
              </span>
              <span v-else-if="data.item.order"
                ><b-link
                  :to="{
                    name: 'orders-view',
                    params: { id: data.item.order.id },
                  }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  Buchung
                </b-link>
              </span>
            </template>

            <template #cell(created_at)="data">
              <span>{{ getFormattedDate(data.value) }}</span>
            </template>

            <template #cell(attachments)="data">
              <viewer
                ref="viewer"
                :options="options"
                class="viewer"
                @inited="inited"
              >
                <b-row
                  v-for="attachment in data.item.attachments"
                  :key="`attachment-${attachment.id}`"
                  cols="12"
                >
                  <!-- Innere Schleife über jedes File im aktuellen Attachment -->
                  <div
                    v-for="file in attachment.files"
                    :key="`file-${file.id}`"
                  >
                    <!-- Annahme: jedes File hat eine ID -->
                    <b-card style="margin: 5px">
                      <b-link
                        v-if="file.mime === 'application/pdf'"
                        target="_blank"
                        :href="`${baseURL}${file.url}`"
                      >
                        <b-img
                          src="@/assets/images/icons/file-icons/pdf.png"
                          alt="PDF Dokument"
                          center
                          class="imageDocument"
                        />
                        <p>{{ file.name }}</p>
                      </b-link>
                      <b-img
                        v-else-if="file.formats && file.formats.thumbnail"
                        :src="`${baseURL}${file.formats.thumbnail.url}`"
                        alt="Bilddokument"
                        center
                        class="image"
                        :data-src="`${baseURL}${file.url}`"
                      />
                      <!-- Fallback für Bilder ohne Thumbnail -->
                      <b-img
                        v-else
                        :src="`${baseURL}${file.url}`"
                        alt="Bilddokument"
                        center
                        class="image"
                      />
                    </b-card>
                  </div>
                </b-row>
              </viewer>
            </template>

            <!-- <template #cell(actions)="data">
              <b-button
                size="sm"
                variant="outline-primary"
                @click="onEdit(data)"
                >Bearbeiten</b-button
              >
              <b-button
                size="sm"
                variant="outline-danger"
                @click="onDelete(data)"
                >Löschen</b-button
              >
            </template> -->
          </b-table>
        </b-card>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BPagination,
  BMedia,
  BMediaAside,
  BLink,
  BImg,
} from 'bootstrap-vue'

import helper from '@/helper/helper'
import attachementHelper from '@/helper/attachementHelper'
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from 'v-viewer'
import API from '@/service/api'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const { getFormattedDate } = helper()
    const { attachementTitleForID } = attachementHelper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      getFormattedDate,
      refetchData,
      attachementTitleForID,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BPagination,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
    Viewer,
  },
  data() {
    return {
      baseURL: API().defaults.baseURL,
      filter: '',
      perPage: 10,
      currentPage: 1,
      sortBy: 'createdAt',
      sortDesc: true,
      fields: [
        // { key: 'id', label: 'ID', sortable: true },
        { key: 'shopUploadEntries', label: 'Bezug', sortable: false },
        { key: 'created_at', label: 'Erstellt am', sortable: true },
        { key: 'email', label: 'E-Mail', sortable: false },
        { key: 'userMessage', label: 'Nachricht', sortable: false },
        { key: 'attachments', label: 'Dateien', sortable: false },

        // { key: 'actions', label: 'Actions' },
      ],
    }
  },

  computed: {
    shopUploadEntries() {
      return this.$store.getters.shopUploadEntries
    },
    hasOffer() {
      return this.$store.getters.hasOffer
    },
    getTotalRows() {
      return this.shopUploadEntries.length
    },
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    show() {
      this.$viewer.show()
    },
  },
}
</script>
<style>
.image {
  height: 114px;
  cursor: pointer;
  margin-right: 5px;
}

.imageDocument {
  height: 80px;
  cursor: pointer;
  margin-right: 5px;
}
</style>
